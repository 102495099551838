const Routes = {
    DASHBOARD: '/dashboard',
    LOGIN: '/api/auth/login',
    LOGOUT: '/api/auth/logout',
    CALLBACK: '/api/auth/callback',
    PROFILE: '/dashboard/profile',
    EXCHANGE: '/dashboard/exchange-settings',
    NOTIFICATION: '/dashboard/notification',
    STRATEGIES: '/dashboard/strategies',
    NEW_STRATEGY: '/dashboard/strategies/new',
    MARKETPLACE: '/dashboard/marketplace',
    BOT_RESULT: '/dashboard/result/{1}',
    CHARTS: '/dashboard/charts',
    BACKTEST_BOTS: '/dashboard/bots/backtest',
    NEW_BACKTEST_BOTS: '/dashboard/bots/backtest/new',
    BOTS_LIST: '/dashboard/bots/list',
    BOT_HISTORY: '/dashboard/bots/history',
    TRADE_BOTS: '/dashboard/bots/trade',
    NEW_TRADE_BOTS: '/dashboard/bots/trade/new',
    OPEN_ORDERS: '/dashboard/live/open-orders',
    TRADE_HISTORY: '/dashboard/live/trade-history',
    VERIFY_YOUR_ACCOUNT_ERROR: '/error/verify-email',
} as const;

export default Routes;
